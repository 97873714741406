// 定义内容
export default {
  menus: {
    home: 'Home',
    knowledge: 'Knowledge',
    gameProduct: 'Game products',
    creator: 'Creators Program',
    aiToolkit: 'AI Toolkit',
    questions: 'Questions'
  },
  editorMenus: {
    articlePublish: 'Write an article',
    postProduct: 'Post images',
    gameDemo: 'Game Demo',
    gameResource: 'Game Resources',
    artCreativity: 'Art Creativity'
  },
  applicationMenus: {
    demoManagement: 'demo management',
    apiKey: 'API key management',
    incentiveAccount: 'Incentive Account',
    billingDetail: 'Consumption Detail',
    developmentDocuments: 'development documents',
    demoTitle: 'My Demo'
  },
  personHome: {
    center: 'Your profile',
    logout: 'Log out'
  },
  personNav: {
    article: 'Article',
    knowledge: 'Knowledge',
    favorite: 'Favorite',
    draftBox: 'Draft Box',
    product: 'Product',
    image: 'Image',
    demo: 'Demo',
    follow: 'Follow'
  },
  following: {
    following: 'Following',
    followers: 'Followers',
    guestFollow: 'Following',
    guestFollowers: 'Followers',
    submissions: 'Submissions',
    views: 'Views',
    likes: 'Likes',
    followed: 'followed',
    unfollow: 'unfollow',
    mutualFollowing: 'mutual following',
    followBtn: 'Follow',
    followedBtn: 'Followed',
    followMutualBtn: 'Mutual Following',
  },
  addNav:{
    publish: 'Publish',
    knowledge: 'Write a article',
    image: 'Post images',
    demo: 'My Demo',
    answer: 'Answer',
    product: 'Post Product',
    posts: 'Posts',
    views: 'Views',
    likes: 'Likes',
  },
  personInfo: {
    userName: 'User name',
    description: 'Description',
    save: 'Save',
    cancel: 'cancel',
    edit: 'Edit',
    delete: 'Delete',
    title: 'Edit Profile',
    tips: 'Save successfully',
    upload: 'Uploading',
  },
  personCard: {
    editDate: 'Edited on',
    edit: 'Edit',
    delete: 'Delete',
    draft: 'Draft',
    reviewFailed: 'Review Failed',
    noData: 'No content yet',
  },
  deleteDraft: {
    title: 'Prompt',
    content: 'Deletion cannot be restored. Are you sure you want to delete this draft?',
    content1: 'Will you delete this article?',
    workContent: 'Will you delete this works?',
    cancel: 'cancel',
    confirm: 'Confirm',
  },
  publishStatus: {
    defaultStatus: 'Posted a article',
    status0: 'draft',
    status1: 'Under review for release',
    status2: 'Publishing review failed',
    status3: 'Approved',
  },
  publishState: {
    draft: 'To be released',
    underReview: 'Under review',
    published: 'Published',
    fail: 'Failed review',
  },
  route: {
    setting: {
      general: 'General',
      password: 'Password',
      connect: 'Connections',
      developer: 'Developer',
    },
    home: {
      browser: 'Home',
      client: 'Home',
    },
    account: {
      login: 'Sign in',
      register: 'Register',
      reset: 'Reset password',
      bind: 'Bind account',
      redirect: 'Redirect',
    },
    oauth: {
      login: 'Oauth authorize',
      consent: 'Oauth authorize',
      redirect: 'Oauth authorize',
    },
    developer: {
      home: 'Home',
      register: 'Sign in',
      application: 'My applications',
      sdk: 'SDK and AI tools',
      document: 'Document',
    },
    article: {
      add: 'article'
    },
    knowledge: {
      home: 'knowledge',
      more: 'Module breakdown',
      detail: 'knowledge detail'
    },
    gameWorks: {
      home: 'game works',
      more: 'Module breakdown',
      detail: 'game works detail'
    },
    error: {
      index: 'Error',
      401: 'No permission',
      404: 'Not found',
    },
    application: {
      list: 'Demo',
      create: 'create new demo'
    },
    personal: {
      home: 'personal'
    }
  },
  views: {
    signUp: {
      title: 'Join LitGate',
      userNamePh: 'Display Name',
      emailPh: 'Email',
      passwordPh: 'Password',
      codePh: 'Email verify code',
      send: 'Send',
      sending: 'sending',
      signUpBtn: 'Sign Up',
      haveAccount: 'Have an account?',
      signInTxt: 'sign in',
      checkPre: 'I have read and agree to the',
      terms: 'terms of service',
      and: 'and',
      policy: 'Privacy Policy',
      passwordTips: 'The password must be between 6 and 30 characters in length, must contain letters and numbers, and no spaces are allowed.',
      success: 'Sign up successful!',
      checkPolicy: 'place agree privacy policy'
    },
    signIn: {
      title: 'Sign in',
      signInBtn: 'Sign in',
      forget: 'Forget Password?',
      noAccount: "Don't have an account?",
      signUpLink: 'sign up',
      success: 'login success'
    },
    forgetPage: {
      title: 'Password reset',
      sendBtn: 'Send Recovery Email',
      noEmail: "Didn't receive the email?",
      sendResultTips: "Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.",
      reSend: 'Resend'
    },
    passwordReset: {
      title: 'Password reset',
      passwordPh: 'Create your new password',
      passwordSd: 'Confirm your new password',
      confirm: 'Confirm',
      success: 'password reset success!',
      toSignIn: 'Go to login'
    },
    game: {
      info: '游戏信息',
    },
    guide: {
      more: 'More Details',
      success: 'subscribe success!',
      emailCheckFail: 'Please enter a valid address',
      banner: {
        tips1: 'KNOWLEDGE, GUIDES, RESOURCES AND MORE FOR AI-EMPOWERED GAMES',
        slogan1: 'DISCOVER THE INNOVATIVE',
        scrollSlogan: ['GAME CREATION', 'AI APPLICATION', 'SPOTLIGHTS&INSIGHTS', 'CAREER GROWTH'],
        slogan3: 'WITH LITGATE COMMUNITY',
        emailTips: 'Coming Soon: Get Notified When We Launch!',
        tips2: 'We are committed to protecting your privacy and ensuring the security of your personal information. When you provide us with your email address, we guarantee that it will only be used for the purpose of sending product update notifications. We will never disclose or share your email address with any third parties without your explicit consent.',
        subscribe: 'SUBSCRIBE',
        placeholder: 'Please enter your email',
        discord: 'Discord',
        wechat: 'Wechat',
      },
      events: {
        title: 'EVENTS',
        waitContent: 'More Upcoming Events',
        eventTimeRange1: 'Oct.20th 2023 - Oct.22nd 2023',
        eventTitle1: 'CiGA GENE AI GameJam',
        eventAddress1: 'SHANGHAI, WUHAN, GUANGZHOU, SHENZHEN',
        eventIntro1: "AIGT (China's AI Game Tournament) is an AI-themed game creation competition hosted by CiGA and sponsored by LitGate. In this event participants will attempt to apply AI technology to game demos and compete for awards. Outstanding entries will receive extensive exposure opportunities, trophies, and also have the chance to connect with a wide range of industry professionals, enthusiasts, and other creators.",
        eventMore: 'More',
        eventTimeRange2: 'Jan. 1th 2024 -Jan. 10nd 2024',
        eventTitle2: 'Global GameJam',
        eventAddress2: 'Global Online & Onsite',
        eventIntro2: "Global Gamejam（GGJ）is the world's largest game creation event taking place around the globe boasting tens of thousands of participants (jammers) at hundreds of physical and virtual sites in over one hundred countries around the world since 2008. Every year, GGJ organizes 48-hour Gamejam events to empower all individuals worldwide to learn, experiment, and create together. LitGate will attend 2024 GGJ as a sponsor, providing AI-related game development themes and offering resource support to creators."
      },
      aboutUs: {
        title: 'About LitGate',
        introTitle1: 'A ROBUST AI&GAME ECOSYSTEM',
        introTitle11: 'A ROBUST',
        introTitle12: 'AI&GAME',
        introTitle13: 'ECOSYSTEM',
        introContent1: "Regardless of whether you're a seasoned creator or simply a gamer embracing the AI revolutions, you're encouraged to take part in creation campaigns or events, and share your thoughts, resources, creations, or any insights as community contributor.",
        stations: [
          { title: 'GAME STATION', label: 'Game center of AI-empowered creations publishing and sharing.'},
          { title: 'GEEK COMMUNITY', label: 'Information exchange, seasoned knowledge. and talent matching.'},
          { title: 'PIONEERING TECH', label: 'Professional tool station of AI-empowered game development.'},
          { title: 'CAREER GROWTH', label: 'Opportunities of techincal support, incubation, and investment.'},
        ],
        introTitle2: ['MORE THAN A COMMUNITY'],
        introTitleGreen2: '',
        introContent2: "We offer creators with a wide variety of open resources and tools, as well as access to paid and proprietary resources developed or sponsored by LitGate. With the dynamic and informative feeds and academy columns, LitGate keeps users up-to-date with what's new and exclusive or first-published articles.  ",
        introTitle3: ['EVENTS AND '],
        introTitleGreen3: 'GAMEJAM',
        introContent3: `We are hosting abundant campaigns and Gamejams worldwide to help creators stand out and get exposed to potential financing investors and incubation opportunities. 
        Here, you could also get connected to the industrial pioneers and well-known leading talents by engaging in their streaming or offline seminars.`,
      },
      case: {
        title: 'SHOWCASES',
        carousel1: {
          title: 'Excellent Works from the Previous Gamejams',
          intro: 'In previous AI-themed game Gamejams, creators actively applied AI techs into game development, pushing the boundaries of gameplay and design, and showcasing boundless creativity and innovation.',
        },
        banner1: {
          title: 'Wraith',
          intro: "The game utilizes AI's real-time capabilities powered by ChatGPT4 to generate new narrative content. With each game start, the system inputs a detailed prompt of around 10,000 words, ensuring the AI's clear understanding of the context.",
          tags: ['Roguelike', 'Horror', 'Real-time production'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner2: {
          title: 'AI Princess Maker',
          intro: 'The game is an anime-style life simulator of "raising a daughter". Powered by Stable Diffusion with real-time calculation, players would experience unique and different images based on their choice and the character points.',
          tags: ['Life simulator', 'RPG', 'Real-time production'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner3: {
          title: 'Fusion',
          intro: 'This card game features diverse card equipment and varied level generation with high replayability and real-time calculation. All the visual assets are produced by Midjourney and Stable Diffusion, while the music is generated by AIVA.',
          tags: ['Cards', 'Strategy', 'Real-time production'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner4: {
          title: 'God Can Talk',
          intro: `An action game where you're the GOD who plays the whole world with your voice! It allows players to "control" characters and "program" a new environment by simply speaking to mic.`,
          tags: ['Sandbox', 'Adventure', 'Action'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
      },
      partner: {
        title: 'RESOURCES PARTNERS'
      },
      contact: {
        title: 'CONTACT US',
        tips: 'If you are interested in business opportunities or have any questions, please contact us at',
        email: 'litgate_official@gmail.com',
        listTitle: 'OUR COSIALS:',
        Wechat: 'Wechat',
        wechatOpen: 'WeChat Account',
        Discord: 'Discord',
        Twitter: 'Twitter'
      },
    },
    editorArticle: {
      titlePh: 'Title (Maximum 100 Words)',
      introPh: 'Short Introduction (Optional)',
      contentPh: 'Content',
      setting: 'Setting',
      category: 'Article Category',
      categoryPh: 'please select article category',
      articleTag: 'Article Tags',
      tagAdd: 'New Tag',
      addCover: 'Add Cover',
      relevantContent: 'Relevant Content',
      relevantAdd: 'Add Content',
      backToTop: 'Back to Top',
      saveTipsPre: 'Draft was saved',
      saveTipsSur: 'mins ago',
      total: 'A total of',
      preview: 'Preview',
      save: 'Save',
      post: 'Post',
      rePostBtn: 'Repost',
      postSuccess: 'post success',
      postError: 'post fail',
      saveError: 'save fail',
      saveSuccess: 'save success',
      titleEmpty: 'please input article title',
      contentEmpty: 'please input article content',
      relatedPost: 'Related Posts',
      search: 'Search',
      keywords: 'Key Words',
      selectCategory: 'select category',
      searchList: 'select list',
      selected: 'Selected',
      cancel: 'cancel',
      coverFormat: 'Image format supports JPEG、JPG、PNG,limit to 1'
    },
    postProductImage: {
      setting: 'Setting',
      title: 'Title',
      titlePl: 'Please Enter The Title',
      imgTag: 'Img Tags',
      introduction: 'Introduction',
      introductionPl: 'Please fill in the introduction of game effects so that more people can find your game effects',
      cancelBtn: 'Cancel',
      saveBtn: 'Save',
      postBtn: 'Post',
      rePostBtn: 'Repost',
      uploadCompleted: 'upload completed',
      uploadFail: 'Incorrect file format',
      setAsCoverBtn: 'Set As Cover',
      coverTips: 'Default cover',
      waitUpload: 'To be uploaded',
      uploading: 'Uploading',
      imageNotReady: 'image not ready',
      dragUpload: 'Drag the image here or click to upload',
      dragUploadLimit: 'A maximum of 20 images can be uploaded',
      releaseUpload: 'Release to upload img',
      uploadBtn: 'Upload',
      draftBtn: 'Drafts',
      sizeTips: 'Image Size',
      sizeTipsContent: 'Size is within 30MB',
      resolutionTips: 'Image Resolution',
      resolutionTipsContent: 'The maximum image support is 1080P',
      formatTips: 'Picture format',
      formatTipsContent: 'recommended to use png, jpg, jpeg, webp, gif',
      postSuccess: 'post success',
      postError: 'post fail',
      saveError: 'save fail',
      saveSuccess: 'save success',
      limitError: "I'm sorry. You can upload up to 20"
    },
    createApplication: {
      generalSetting: 'General Information',
      publishSetting: 'Publish Setting',
      createBtn: 'Create a new demo',
      name: 'Demo Name',
      introduction: 'Introduction',
      platform: 'Supported Platform',
      gameDemo: 'Game Demo',
      demoUrl: 'Demo URL',
      recentPublishedDate: 'Recent Published Date',
      titlePlaceHolder: 'Please Enter The Title',
      introductionPlaceHolder: 'Please fill in the game demo profile,so that more people can find your game demo',
      demoUrlPlaceHolder: 'Please enter the demo address for the URL',
      promotionMaterials: 'Promotion Materials',
      limitError: "I'm sorry. You can upload up to 20",
      cover: 'Cover Image',
      tags: 'Tags',
      uploadBtn: 'Upload compressed file',
      uploadFormatTips: 'Only for compressed package files, file size cannot exceed 1GB',
      uploadPromotionMaterials: 'Upload Materials',
      promotionMaterialsError: 'promotion materials is required',
      demoError: 'demo is required',
      demoUrlError: 'please input your demo url',
      joinCreatorBtn: 'Join creator program',
      reJoinCreatorBtn: 'View more',
      materialsTips: {
        supported: '· Format: Supports common formats for videos and pictures, only up to 20 materials can be uploaded',
        size: '· Size: Video size should be within 1G, image size should be within 30MB',
        resolution: '· Resolution: 16:9',
        format: '· Formats: Common video formats supported, images support JPEG, JPG, PNG；',
      },
      joinCreator: {
        createOption: 'Create a new Demo',
        title: 'Join Creators Program',
        tips: 'Successfully joining the Creator Program can earn you free Token incentives. Please fill in the following information and submit your application.',
        replyTips: 'Please modify the application information below and resubmit it for review.',
        demoName: 'Title of work',
        demoNamePH: 'Please enter the title of your work',
        selectDemo: 'Select Works',
        selectDemoPH: 'Please select a work',
        intro: 'Introduction',
        introPH: 'Please introduce your work so that we can understand your ideas more quickly',
        reSubmit: 'Resubmit',
        submit: 'Submit',
        updateDraftTitle: 'Update Draft',
        updateDraftContent: 'The application content is inconsistent with the draft content, whether to update the draft content',
        updateDraftSubmit: 'confirm',
        updateDraftCancel: 'cancel',
        successMsg: 'Submission success',
        joinStatus: 'Join creator program',
      },
      apiDocument: 'API Document'
    },
    apiKeyManager: {
      instruction: 'Instructions for use',
      instructionList: [
        "After successfully joining LitGate's creator program, you can create LitGate's exclusive APl Key for your project, Using this APl Key, your project will be able to use free token quota.",
        "Do not share your APl Key with others or expose it to client code.",
        "To protect the security of your account, LitGate may disable APl keys once they are found to be compromised"
      ],
      tableTitle: 'API Key',
      createBtn: 'New',
      tableTh: {
        name: 'Name',
        creationTime: 'Creation time',
        key: 'Key',
        binding: 'BinDing Demo',
        operate: 'Operate',
        editBtn: 'Edit',
        deleteBtn: 'Delete'
      },
      dialog: {
        createTitle: 'Create LitGate API Key',
        createTips: 'Each project that successfully joins the Creator Program can onlycreate 1 Key.',
        name: 'Key Name',
        demo: 'Demo',
        submit: 'Submit',
        resultTitle: 'API Key',
        resultTips: 'Please store this API key in a secure and accessible place. For security reasons, you will not be able to view it again through your LitGate platform incentive account. If you lose this key, please generate a new one.',
        sure: 'Sure',
        editTitle: 'Edit',
        namePH: 'Please fill in your name',
        demoPH: 'Please choose a demo',
        deleteTitle: 'Are you sure you want to delete the API Key?',
        deleteTips: 'Deleting an APl Key will cause APl requests from that Key to berejected, which may prevent systems that rely on it from functioningproperly. This operation cannot be undone, please confirm carefully.',
        cancel: 'Cancel',
        
      }
    },
    incentiveAccount: {
      title: 'Account Overview',
      usd: 'USD',
      cny: 'CNY',
      balance: 'Balance',
      totalIncentive: 'Gift amount',
      yesterday: "Yesterday's consumption",
      totalConsumption: 'Total consumption',
      monthTotal: "Consumption this month",
      detailTitle: 'Account details',
      keyGenerated: 'A new key has been generated',
      tableTh: {
        demo: 'Demo',
        apiKey: 'API Key',
        balance: 'Balance',
        valid: 'Valid to',
        totalIncentive: 'Total Incentive',
        totalConsumption: 'Total consumption',
        operate: 'Operate',
        createBtn: 'Create Key',
        changeBtn: 'Change Key',
        usage: 'Usage limit',
        incentiveTime: 'Time',
        incentiveGift: 'Incentive amount',
        balanceLessTips: {
          tips1: 'Your balance has 5 days left to expire, ',
          tips2: 'Please try to',
          tips3: 'apply for an extension of validity',
          tips4: 'Your balance has too less, Please try to',
          tips5: 'Application for balance increase',
          submitSuccess: 'You have successful submitted an application to increase your balance, please  wait patiently for review.'
        }
      },
      dialog: {
        usageLimitTitle: 'Usage Limit',
        limitProps: 'Limit maximum daily usage',
        limitLimitError: 'The limit cannot exceed, up to',
        limitPH: 'Enter a maximum daily usage limit, up to',
        cancel: 'Cancel',
        submit: 'Submit',
        incentiveDetailTitle: 'Incentive details',
        sure: 'Sure',
        deleteTitle: 'Tips',
        deleteTips: 'Are you sure you want to change this API Key?',
      }
    },
    consumptionDetail: {
      startDate: 'Start date',
      endDate: 'End date',
      demo: 'Demo',
      model: 'Model',
      searchBtn: 'Search',
      modelDraft: 'unknown',
      modelAll: 'All',
      openAI: 'Open AI',
      gpt35: 'GPT 3.5',
      gpt4: 'OpenAi GPT4.0',
      gpt4o: 'OpenAi GPT4o',
      dalle3: 'OpenAi Dalle 3',
      tts1: 'OpenAi tts-1',
      tts1hd: 'OpenAi tts-1-hd',
      wisper: 'OpenAi wisper',
      claude: 'Claude haiku',
      claudeSonnet: 'Claude sonnet',
      claudeSonnet35: 'Claude 3.5 Sonnet',
      demoPH: 'Please select a work',
      tableTh: {
        requestId: 'Request ID',
        time: 'Time',
        demo: 'Demo',
        model: 'Model',
        consumption: 'Consumption',
        dateRangeTips: 'Please select a date range'
      }
    },
    productImageDetail: {
      viewMore: 'View more',
      share: 'Share',
    },
    gameWorks: {
      releaseTime: 'Release date',
      gameDemoTitle: 'Game Demo',
      creativityTitle: 'Art Creativity',
      category1: 'Game Demo',
    },
    gameWorksDetail: {
      views: 'views',
      introduction: 'Introduction',
      posted: 'Posted On',
      share: 'Share',
      version: 'version',
      lastUpdateDate: 'Last Update Date',
      supportedPlatform: 'Supported Platform',
      downloadCounts: 'Experience Counts',
      downloadBtn: 'Download Now',
      onlineBtn: 'Online Experience',
      downloadDisableTips: 'Not yet open',
      related: 'Related Tutorials'
    },
    gameWorksMore: {
      category1Desc: 'It mainly includes game works that use AIGC to generate game elements and introduce AIGC to achieve gameplay innovation'
    },
    knowledge: {
      category: 'Category',
      category0: 'Recommended',
      category1: '2D Art',
      category2: '3D Modeling',
      category3: 'Video Animation',
      category4: 'Audio Generation',
      category5: 'Text Generation ',
      category6: 'Coding Assistance',
      category7: 'AI Application in Game Producing',
      category8: 'AI-empowered Game Creations',
      category9: 'AI Tech Resource and Reviews',
      category10: 'AI Academic and Theory Hub',
      category11: 'Top Hits and Trends',
      edit: 'Post Knowledge',
      follow: 'Follow',
      hot: 'Hot',
      more: 'View more',
      articleViews: 'Article views',
      favoriteArticle: 'Favorite article',
      writeArticle: 'Write article',
      postBtn: 'Write an article'
    },
    knowledgeMore: {
      category7Desc: 'Explore how AI tech is applied in various stages of game production.',
      category8Desc: 'Gain inspiration and know-how from excellent showcases of AI-empowered creations.',
      category9Desc: 'Discover reviews and recommendation to select appropriate AI tools for your needs.',
      category10Desc: 'Learn about cutting-edge insights and engage in technical discussions.',
      category11Desc: 'Stay up-to-date on industry topics and news.',
    },
    knowledgeDetail: {
      lead: 'Intro',
      relevant: 'Relevant',
      directory: 'Directory'
    },
    tokenActivity: {
      fixedBtn: 'join',
      intro: {
        title: 'EVENT DESCRIPTION',
        subTitle: '',
        date: 'Event Time:2024.07.01-09.01',
        markTxt: 'This is a great AI era, where every idea deserves respect and a chance to be tried',
        platformTips: '<span class="high-light-txt">LitGate</span>&nbsp;platform initiates the&nbsp;<span class="high-light-txt">#AI Creator Incentive Plan#</span>',
        target: 'Aiming to help AI creators validate their ideas with&nbsp;<span class="high-light-txt">lower cost</span>. Come and &nbsp;<span class="high-light-txt">apply to join</span>!'
      },
      way: {
        title: 'WAY OF PARTICIPATION',
        subTitle: '',
        submitTitle: 'Submit Application',
        submitIntro: 'Fill in the information such as work name and brief summary, etc.',
        submitTips: '(Works include but are not limited to tools, games, general entertainment, etc.)',
        auditTitle: 'Platform Review',
        auditIntro: 'The platform will complete the review within 3 working days after submission',
        joinTitle: 'Join The Plan',
        joinIntro: 'Works that successfully join the plan will be rewarded with free API Token incentives',
        joinTips: '(For details on how to use Token incentives, see the <span class="high-light-txt">API documentation</span>)'
      },
      incentives: {
        title: 'INCENTIVE DETAILS',
        subTitle: '',
        beginStep: {
          title: '《Initial Incentive》',
          content1: 'Successfully join the program',
          tips1: 'Incentive of millions of free Tokens',
        },
        onlineStep: {
          title: '《Online Incentive》',
          content1: 'Release and go online',
          tips1: 'Incentive of up to 200 million free Tokens'
        },
        gloryStep: {
          title: '《Glory Incentive》',
          content1: 'Share the story behind the creation',
          tips1: 'Incentive of millions of free Tokens each time',
          content2: 'Work goes viral, continuous incentives',
          tips2: 'Incentive of up to 200 million free Tokens each time'
        },
        extraTips1: 'The above free Token amounts are based on GPT3.5 as a reference, and the models we support include',
        extraTips2: 'GPT4o/GPT4/GPT3.5/Claude3',
        extraTips3: 'For details on the use of free Tokens, see the',
        extraTips4: 'API documentation',
      },
      notice: {
        title: 'EVENT INSTRUCTIONS',
        subTitle: '',
        tips1: 'Creators must ensure that the submitted information is true and valid. If any false information is found, the participation qualification will be canceled;',
        tips2: 'This event does not charge any fees. Do not pay any third party to obtain participation qualifications;',
        tips3: 'The purpose of this event is to encourage exploration and innovation. The Tokens given by the platform are only for use in works created during this event and cannot be transferred or exchanged for cash;',
        tips4: 'All works must not contain political, vulgar, pornographic, religious content, or violate relevant laws and regulations;',
        tips5: 'If the work involves infringement disputes, the creator must bear the corresponding legal responsibilities;',
        tips6: 'LitGate platform reserves the final interpretation right of the event rules. If you have any questions about this event, please contact LitGate platform customer service;'
      }
    },
    page404: {
      title: 'page not found',
      btn: 'Go back home'
    }
  },
  common: {
    ok: 'Ok',
    cancel: 'Cancel',
    and: 'and',
    continue: 'Continue',
    nickname: 'Display Name',
    email: 'Email',
    country: 'Country',
    password: 'Password',
    curPassword: 'Current password',
    newPassword: 'New password',
    sndPassword: 'Confirm password',
    save: 'Save',
    giveup: 'Discard',
    tips: 'Tips',
    logoutTips: 'This operation will log you out, do you want to continue?',
    logouting: 'logging out',
    favorite: 'Favorite',
    favorited: 'Favorited',
    copyLink: 'Copy Link'
  },
  plchld: {
    email: 'Email',
    password: 'Password',
    passwordCur: 'Current password',
    passwordNew: 'New password',
    passwordSnd: 'Confirm password',
    country: 'Country',
    nickname: 'Display Name',
    verifyCode: 'Verify Code',
    firstname: 'First name',
    lastname: 'Last namme',
  },
  tips: {
    charLen: 'English occupies 1 character, Chinese 2 characters',
    succ: {
      get: 'Pull data successfully',
      connect: 'Connect successfully',
      disconnect: 'Disconnect successfully',
      add: 'Add successfully',
      edit: 'Modify successfully',
      del: 'Delete successfully',
      login: 'Sign in successfully',
      submit: 'Submitted successfully',
      copy: 'copy success'
    },
    fail: {
      get: 'Pull data failed',
      connect: 'Connect failed',
      disconnect: 'Disconnect failed',
      add: 'Add failed',
      edit: 'Modify failed',
      del: 'Delete failed',
      closed: 'Unavailable temporarily',
      submit: 'Submit failed',
    },
  },
  comment: {
    viewMore: 'more view',
    title: 'Comments',
    delete: 'Delete',
    report: 'Report',
    copy: 'Copy',
    commentPlaceHolder: 'Enter your comment',
    loginTips: 'Please sign in before you can comment',
    loginBtn: 'Login in',
    replyFor: 'Reply',
    commentSuccess: 'comment success',
    replySuccess: 'reply success',
    deleteSuccess: 'delete success',
    applySuccess: 'apply success',
    postBtn: 'Post'
  },
  gateReport: {
    title: 'Report',
    reportType: 'Please fill in the report type',
    hostility: 'Hostility',
    advertising: 'Junk advertising',
    violateLaws: 'Violate laws',
    eroticism: 'Eroticism',
    suspectedInfringement: 'Suspected infringement',
    cyberViolence: 'Cyber violence',
    underageInvolvement: 'Underage involvement',
    suicide: 'Suicide and self-harm',
    falseInformation: 'False information',
    infoTitle: 'Please fill in the report information',
    submitBtn: 'Submit',
    inputPl: 'Please enter content',
    success: 'Complaint success',
    repeat: 'reported',
    reportErr: 'System error, please try again later'
  },
  error: {
    userNameEmpty: 'Display Name cannot be empty',
    userNameLengthError: 'Display Name length should be between 2-20 characters',
    userNameStringError: 'Display Name should not contain special characters',
    userNameFirstCharError: 'The first character of display name cannot be a number',
    emailEmpty: 'Email cannot be empty',
    emailInvalid: 'Email is invalid or already taken',
    emailSignInInvalid: 'Email is invalid',
    passwordEmpty: 'Password cannot be empty',
    passwordLengthError: 'Password length should be between 6 and 30 characters',
    passwordLevelError: 'Password should include letters and numbers',
    verifyCodeEmpty: 'Email verify code cannot be empty',
    content: 'Please enter content',
    email: 'Please enter email',
    emailFormat: 'Please enter email in the correct format',
    password: 'Please enter password',
    passwordCur: 'Please enter current password',
    passwordNew: 'Please enter new password',
    passwordSnd: 'Please enter confirm password',
    pswNotSame: 'The two passwords do not match',
    pswOldSame: 'The old and new password are the same, please re-enter',
    country: 'Please enter country',
    nickname: 'Please enter display Name',
    verifyCode: 'Please enter verify code',
    verifyCodeFormat: 'Please enter verify code in the correct format',
    lengthAt: 'The length is between ',
    notBlank: ' and cannot contain spaces',
    withSpecialChar: ', can contain special characters such as @#$',
    sameEmailPsw: 'Password and email are too similar',
    protocol: 'Please agree to the agreement first',
    overMax: 'Length exceeds limit',
    format: 'format wrong',
    backspace: 'cannot contain spaces',
    fileSizeLimit: 'The file size exceeds the limit',
    fileRatioLimit: 'The file width/height ratio does not conform to the rules'
  },
  accountCode: {
    code1301: 'Your account is abnormal, please try again later',
    code1302: 'Your account is abnormal, please try again later',
    code1303: 'Display Name cannot be empty',
    code1304: 'Display Name length should be between 2-20 characters',
    code1305: 'Display Name should not contain special characters',
    code1306: 'Email is invalid',
    code1307: 'Email is invalid or already taken',
    code1308: 'Resend the email after 60 seconds,please try again later',
    code1309: 'The times of verification codes sent today has reached the limit',
    code1310: 'Incorrect email or password',
    code1311: "Display Name don't follow the rules",
    code1312: 'Email is invalid or already taken',
    code1313: 'Incorrect email verify code',
    code1314: 'Email verify code has expired,please request a new one.',
    code1315: 'The times of failed logins today has reached the upper limit',
    code1316: 'Incorrect email or password',
    code1317: 'The account is abnormal, please contact customer service',
    code1318: 'The password format is incorrect, please re-enter',
  },
  options: {
    articleCategory: {
      category1: '2D Art',
      category2: '3D Modeling',
      category3: 'Video Animation',
      category4: 'Audio Generation',
      category5: 'Text Generation ',
      category6: 'Coding Assistance',
      category7: 'AI Application in Game Producing',
      category8: 'AI-empowered Game Creations',
      category9: 'AI Tech Resource and Reviews',
      category10: 'AI Academic and Theory Hub',
      category11: 'Top Hits and Trends',
    }
  },
  auditState: {
    wait: 'Applying',
    success: 'Success',
    fail: 'Fail'
  },
  components: {
    table: {
      editBtn: 'Edit',
      deleteBtn: 'Delete'
    }
  }
};
