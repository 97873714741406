<template>
  <div class="player-chat-mg">
    <div :class="msgInfo.version.indexOf('gpt') >= 0 ? 'ai-avatar ai-gpt-avatar' : 'ai-avatar ai-claude-avatar'">
    </div>
    <div class="container-wrap" >
      <!-- <div class="name">{{ formatName(msgInfo.version) }}</div> -->
      <div class="name">宠物生成器</div>
      <div class="msg-container" v-if="msgInfo.messageType === MessageTypes.Chat" >
        <div class="msg" v-if="msgInfo.msgType === MsgType.Text || msgInfo.msgType === MsgType.Image">{{ msgInfo.msg }}</div>
        <div class="image" v-if="msgInfo.msgType === MsgType.Image">
          <img :src="msgInfo.image" alt="">
        </div>
      </div>
      <div class="msg-container wait-container" v-if="msgInfo.messageType === MessageTypes.Wait">
        <div class="wait-txt">宠物生成中 <span>...</span></div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import defaultAvatar from '@/assets/knowledge/avatar-default.png'
  import { chatMsgShowModeTypes, MessageTypes } from '@/config/aiInspect'
  import { MsgType } from '@/config/tokenTest';

  export default {
    name: 'player-chat-mg',
    props: {
      msgInfo: {
        type: Object,
        default: () => {},
      }
    },
    data() {
      return {
        defaultAvatar,
        MessageTypes,
        chatMsgShowModeTypes,
        MsgType
      }
    },
    mounted() {
      console.log('msgInfo', this.msgInfo)
    },
    methods: {
      formatName(name) {
        return name.indexOf('gpt') >= 0 ? 'gpt' : 'claude';
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  .player-chat-mg{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    .ai-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #999;
      .ai-avatar-txt {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 36px;
        font-size: 24px;
      }
    }
    .ai-gpt-avatar{
      background-image: url('@/assets/aiInspect/tokenTest/gpt4.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
    .ai-claude-avatar{
      background-image: url('@/assets/aiInspect/tokenTest/claude-3.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
    .container-wrap{
      margin-left: 12px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .name{
        color: #999;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
      }
      .msg-container{
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-end;
        padding: 12px;
        gap: 5px;
        max-width: 540px;
        .title{
          font-size: 14px;
          font-weight: bold;
          color: #000;
          margin-top: 5px;
        }
        .msg{
          font-size: 14px;
          color: #333;
          white-space: pre-line
        }
        .time{
          font-size: 12px;
          font-weight: 400;
          color: #999;
        }
        .image{
          img{
            margin-bottom: 5px;
            max-width: 100%;
          }
        }
      }
      .wait-container{
        width: 240px;
        .wait-txt{
          width: 100%;
          text-align: left;
          span{
            display: inline-block;
            height: 1em;
            line-height: 1;
            text-align: left;
            vertical-align: -0.25em;
            overflow: hidden;
          }
          span::before{
            display: block;
            content: '...\A..\A.';
            white-space: pre-wrap;
            animation: span 1.5s infinite step-start both;
          }
          @keyframes span{
            33%{ transform: translateY(-2em); }
            66%{ transform: translateY(-1em); }
          }
        }
      }
    }
   
  }
</style>
  