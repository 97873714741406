<template>
  <div class="lit-table-wrap">
    <el-table
      :data="tableData"
      :height="height > 0 ? height : null"
      :cell-style="cellStyle"
      :header-cell-style="getComputedStyle"
      :header-row-style="{background: 'transparent'}"
      stripe
      style="width: 100%">
    <template v-for="(item, index) in columns">
      <el-table-column
        v-if="!item.type"
        :prop="item.prop"
        :key="'column' + index"
        :label="$t(item.label)"
        :align="item.align || 'center'"
        >
        <template slot-scope="scope">
            <span class="value-txt">{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      <el-table-column
        v-if="item.type === Table_Column_Types.Time"
        :key="'column' + index"
        :label="$t(item.label)"
        :align="item.align || 'center'"
        >
          <template slot-scope="scope">
            <span class="value-txt">{{ item.showTxt ? item.showTxt(scope.row[item.prop]) : showTime(scope.row[item.prop], item.timeFormat || 'YYYY-mm-dd') }}</span>
          </template>
        </el-table-column>
      <el-table-column
        v-if="item.type === Table_Column_Types.Detail"
        :key="'column' + index"
        :label="$t(item.label)"
        :width="item.width"
        :align="item.align || 'center'"
        >
          <template slot-scope="scope">
            <span class="detail-text value-txt" @click="item.onClick && item.onClick(scope.row)">{{ item.showTxt ? item.showTxt(scope.row[item.prop]) : scope.row[item.prop] }}</span>
          </template>
      </el-table-column>
      <el-table-column
        v-if="item.type === Table_Column_Types.Custom"
        :key="'column' + index"
        :label="$t(item.label)"
        :align="item.align || 'center'"
        >
          <template slot-scope="scope">
            <span class="value-txt" @click="item.onClick && item.onClick(scope.row)">{{ item.showTxt ? item.showTxt(scope.row[item.prop]) : scope.row[item.prop] }}</span>
          </template>
      </el-table-column>
      <el-table-column
        v-if="item.type === Table_Column_Types.Balance"
        :key="'column' + index"
        :label="$t(item.label)"
        :align="item.align || 'center'"
        >
          <template slot-scope="scope">
            <div class="detail-box">
              <span class="value-txt">{{ item.showTxt ? item.showTxt(scope.row[item.prop]) : scope.row[item.prop] }}</span>
              <el-popover
                placement="top"
                width="350"
                trigger="click"
                v-if="scope.row[item.prop] < 2 && scope.row.apply_balance == Token_Apply_State.Gate_No"
              >
                <div :style="{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'flex-start', gap: '4px', background: '#000', padding: '13px 16px'}">
                  <div class="tips" :style="{color: '#999', fontSize: '14px', fontStyle: 'normal', fontWeight: 500, lineHeight: '22px'}">
                    {{ $t('views.incentiveAccount.tableTh.balanceLessTips.tips4') }}
                    <span class="value-txt" :style="{color: '#00FFF6', fontSize: '14px', fontStyle: 'normal', fontWeight: 500, lineHeight: '22px', textDecorationLine: 'underline', cursor: 'pointer'}" @click="item.onClick && item.onClick(scope.row)">{{ $t('views.incentiveAccount.tableTh.balanceLessTips.tips5') }}</span>
                  </div>
                </div>
                <i class="el-icon-warning-outline" :style="{ color: 'red', cursor: 'pointer' }" slot="reference" ></i>
              </el-popover>
              <el-popover
                placement="top"
                width="350"
                trigger="click"
                v-if="scope.row.apply_balance == Token_Apply_State.Gate_Yes"
              >
                <div :style="{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'flex-start', gap: '4px', background: '#000', padding: '13px 16px'}">
                  <div class="tips" :style="{color: '#999', fontSize: '14px', fontStyle: 'normal', fontWeight: 500, lineHeight: '22px'}">{{ $t('views.incentiveAccount.tableTh.balanceLessTips.submitBalanceSuccess') }}</div>
                  
                </div>
                <i class="el-icon-time" :style="{ color: '#ADADAD', cursor: 'pointer' }" slot="reference"></i>
              </el-popover>
            </div>
          </template>
      </el-table-column>
      <el-table-column
        v-if="item.type === Table_Column_Types.CanRenewTime"
        :key="'column' + index"
        :label="$t(item.label)"
        :align="item.align || 'center'"
        >
          <template slot-scope="scope">
            <div class="detail-box">
              <span class="value-txt">{{ showTime(scope.row[item.prop]) }}</span>
              <el-popover
                placement="top"
                width="350"
                trigger="click"
                v-if="showCanRenewTime(scope.row[item.prop], scope.row)"
              >
                <div :style="{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'flex-start', gap: '4px', background: '#000', padding: '13px 16px'}">
                  <div class="tips" :style="{color: '#999', fontSize: '14px', fontStyle: 'normal', fontWeight: 500, lineHeight: '22px'}">
                    {{ $t('views.incentiveAccount.tableTh.balanceLessTips.tips1') }} {{ showBalanceDay(scope.row[item.prop]) }} {{ $t('views.incentiveAccount.tableTh.balanceLessTips.tips2') }}
                    <span class="value-txt" :style="{color: '#00FFF6', fontSize: '14px', fontStyle: 'normal', fontWeight: 500, lineHeight: '22px', textDecorationLine: 'underline', cursor: 'pointer'}" @click="item.onClick && item.onClick(scope.row)">{{ $t('views.incentiveAccount.tableTh.balanceLessTips.tips3') }}</span>
                  </div>
                </div>
                <i class="el-icon-warning-outline" :style="{ color: 'red', cursor: 'pointer' }" slot="reference" ></i>
              </el-popover>
              <el-popover
                placement="top"
                width="350"
                trigger="click"
                v-if="scope.row.apply_expire_time == Token_Apply_State.Gate_Yes"
              >
                <div :style="{display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'flex-start', gap: '4px', background: '#000', padding: '13px 16px'}">
                  <div class="tips" :style="{color: '#999', fontSize: '14px', fontStyle: 'normal', fontWeight: 500, lineHeight: '22px'}">{{ $t('views.incentiveAccount.tableTh.balanceLessTips.submitDateSuccess') }}</div>
                  
                </div>
                <i class="el-icon-time" :style="{ color: '#ADADAD', cursor: 'pointer' }" slot="reference"></i>
              </el-popover>
            </div>
          </template>
      </el-table-column>
    </template>
      <el-table-column
        v-if="operates.length > 0"
        :align="operatesAlign"
        width="220"
        :label="$t('views.apiKeyManager.tableTh.operate')">
        <template slot-scope="scope">
          <div class="btn-box">
            <div class="table-btn" v-show="operateShow(handleItem, scope.row)" @click="handleItem.onClick && handleItem.onClick(scope.row, handleItem)" v-for="(handleItem, index) in operates" :key="'operate' + index">{{ $t(handleItem.label) }}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="lit-table-pagination"
      v-if="pagination && currentPagination.total > 0"
      :total="currentPagination.total"
      background
      :current-page="currentPagination.current"
      :layout="currentPagination.layout"
      :page-sizes="currentPagination.pageSizes"
      :page-size="currentPagination.size"
      @size-change="sizeChange"
      @current-change="currentChange"
    />
  </div>
</template>
  
<script>
import { Table_Column_Types } from '@/config/table';
import { formatDate } from '@/utils/format';
import { Token_Apply_State } from '@/config/token'


export default {
  name: 'post-demo-list',
  components: {  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: 0,
    },
    tableData: {
      type: Array,
      default: () => []
    },
    operates: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: null
    },
    cellStyle: {
      type: Object,
      default: null
    },
    headerCellStyle: {
      type: Object | Function,
      default: null
    },
    operatesAlign: {
      type: String,
      default: 'left'
    },
  },
  data () {
    return {
      Table_Column_Types,
      Token_Apply_State,
      loading: false,
      defaultHeaderCellStyle: {
        borderBottom: '1px solid #141414',
        backgroundColor: '#141414',
        color: '#666'
      },
      currentHeaderCellStyle: {},
      columnsEx: [
        { label: 'views.apiKeyManager.createBtn', prop: 'data' },
      ],
      operateEx: [
        { label: 'views.apiKeyManager.add', type: 1 }
      ],
      defaultPagination: {
        current: 1,
        size: 10,
        pageSizes: [10, 30, 50],
        total: 0,
        layout: 'prev, pager, next',
        start: 0,
        limit: 10,
      },
      applyLimit: 24 * 60 * 60 * 10
    }
  },
  mounted () {
  },
  computed: {
    showPagination() {
      if (this.pagination) {
        return Object.keys(this.pagination).length !== 0;
      }
      return false
    },
    currentPagination() {
      console.log('new:', Object.assign({}, this.defaultPagination, this.pagination))
      return Object.assign({}, this.defaultPagination, this.pagination);
    }
  },
  methods: {
    getComputedStyle(arg) {
      if (typeof this.headerCellStyle === 'function') {
        console.log('arg:', arg)
        const style = this.headerCellStyle(arg);
        return {
          ...this.defaultHeaderCellStyle,
          ...style
        };
      }
      return { ...this.defaultHeaderCellStyle, ...this.headerCellStyle };
    },
    operateShow(handleItem, row)  {
      if (!handleItem.onShow) return true;
      return handleItem.onShow(row);
    },
    onRenewal(row) {

    },
    sizeChange(size) {
      this.$emit("onChangePage", this.currentPagination.current, size)
    },
    currentChange(current) {
      this.$emit("onChangePage", current, this.currentPagination.size)
    },
    showTime(time, format = 'YYYY-mm-dd') {
      const date = new Date(Number(time) * 1000);
      return formatDate(date, format);
    },
    showCanRenewTime(data, row) {
      const current =  Date.now() / 1000;
      return Number(data) - current  < this.applyLimit && row.apply_expire_time == Token_Apply_State.Gate_No
    },
    showBalanceDay(data) {
      const current =  Date.now() / 1000;
      console.log((Number(data) - current) / (24 * 60 * 60))
      return  Math.round((Number(data) - current) / (24 * 60 * 60))
    }
  },
    computed: {
    currentPagination() {
      return Object.assign({}, this.defaultPagination, this.pagination);
    },
  },
  destroy() {
  }
}
</script>
<style lang="scss" scoped>
.lit-table-wrap{
  :deep(.el-table, .el-table__expanded-cell){
    background-color: transparent;
  }
  :deep(.el-table__cell.gutter) {
    background: transparent;
  }
  // :deep(.el-table th.el-table__cell) {
  //   background-color: #141414;
  //   border-bottom: 1px solid #141414;
  // }
  
  :deep(.el-table::before){
    background-color: transparent;
  }
  :deep(.el-table tr){
    background-color: transparent;
  }
  :deep(.el-table__body tr:hover>td){
    background-color: transparent !important;
  }
  :deep(.el-table td.el-table__cell){
    border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
  }
  :deep(.el-table td.el-table__cell){
    border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
  }
  :deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell){
    background: transparent !important;
  }
  :deep(.el-table .cell){
    font-size: 14px;
  }
  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
    background-color: #ddd;
    color: #0f0f0f;
  }
  .detail-text {
    text-decoration: underline;
    color: #ADADAD;
    cursor: pointer;
  }
  .value-txt{
    color: #ADADAD;
  }
  .detail-box{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .apply-box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .tips{
      color: #999;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      span{
        color: #00FFF6;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        text-decoration-line: underline;
      }
    }
  }
  .btn-box{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    .table-btn{
      color: #00FFF6;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      opacity: 0.8;
      cursor: pointer;
    }
  }
  .lit-table-operate-btn{
    border: 1px solid transparent;
    color: #00FFF6;
    background: transparent !important;
    width: 48px;
  }
  .lit-table-operate-btn:hover{
    opacity: 0.8;
  }
  .lit-table-pagination{
    margin-top: 28px;
    text-align: right !important;
  }
  :deep(.el-pagination .btn-next){
    background: transparent !important;
  }
  :deep(.el-pagination .btn-prev){
    background: transparent !important;
  }
}
</style>