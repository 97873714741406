import request from '@/utils/request';

const BaseUrl = `/api/gatetown`;

// 获取Uid和房间号
export async function getUid() {
  return request({
    url: `${BaseUrl}/getuid`,
    method: 'post',
    data: {}
  });
}

// 获取Uid和房间号
export async function setRole(data: any) {
  return request({
    url: `${BaseUrl}/setrole`,
    method: 'post',
    data
  });
}

// 加入房间
export async function joinRoom(data: any) {
  return request({
    url: `${BaseUrl}/joinroom`,
    method: 'post',
    data
  });
}

// 开始游戏
export async function startGame(data: any) {
  return request({
    url: `${BaseUrl}/startgame`,
    method: 'post',
    data
  });
}

// 查询房间状态
export async function getRoomState(data: any) {
  return request({
    url: `${BaseUrl}/getroomstatus`,
    method: 'post',
    data
  });
}






// 获取房间里的玩家
export async function getRoomUsers(data: any) {
  return request({
    url: `${BaseUrl}/getroomusers`,
    method: 'post',
    data
  });
}

// 聊天： 开始聊天=>聊天=>结束聊天
// 开始聊天
export async function startChat(data: any) {
  return request({
    url: `${BaseUrl}/startchat`,
    method: 'post',
    data
  });
}

// 聊天
export async function userChat(data: any) {
  return request({
    url: `${BaseUrl}/userchat`,
    method: 'post',
    data
  });
}

// 结束聊天
export async function endChat(data: any) {
  return request({
    url: `${BaseUrl}/endchat`,
    method: 'post',
    data
  });
}

// 结束
export async function endGame(data: any) {
  return request({
    url: `${BaseUrl}/endgame`,
    method: 'post',
    data
  });
}

// 小互动
export async function playAction(data: any) {
  return request({
    url: `${BaseUrl}/action`,
    method: 'post',
    data
  });
}

// 小游戏
export async function playGame(data: any) {
  return request({
    url: `${BaseUrl}/playgame`,
    method: 'post',
    data
  });
}

//tokenGPT聊天
export async function tokenChatGPT(data: any) {
  return request({
    url: `/api/token/openai/v1/chat/completions`,
    method: 'post',
    data: data.data,
    headers: {
      'Authorization': `Bearer ${data.token}`
    }
  });
}

//token claude聊天
export async function tokenChatClaude(data: any) {
  return request({
    url: `/api/token/claude/v1/message`,
    method: 'post',
    data: data.data,
    headers: {
      'Authorization': `Bearer ${data.token}`
    }
  });
}

//发送音频转文字
export async function TokenSendAudio(data: any) {
  return request({
    url: `/api/token/openai/v1/audio/transcriptions`,
    method: 'post',
    data: data.data,
    headers: {
      'Authorization': `Bearer ${data.token}`
    }
  });
}

//发送文字转语音
export async function TokenSendText(data: any) {
  return request({
    url: `/api/token/openai/v1/audio/speech`,
    method: 'post',
    data: data.data,
    responseType: 'blob',
    headers: {
      'Authorization': `Bearer ${data.token}`,
    }
  });
}

//prompt
export async function promptData(data: any) {
  return request({
    url: `/comfyui/prompt`,
    method: 'post',
    data,
  });
}


