<template>
  <div class="ai-single-chat-page">
    <div class="chat-wrap">
      <ChatView :chatMsgs="chatMsgs" ref="chatViewRef" />
      <SendBox v-if="isChat" @sendMsg="sendMessage" :chatMsgs="chatMsgs" :version="version" ref="sendBoxRef" />
    </div>
  </div>
  </template>
  <script>
  import ChatView from './components/chatView.vue';
  import SendBox from './components/sendBox.vue';
  import { MessageTypes } from '@/config/aiInspect'
  import { tokenChatGPT, promptData } from '@/api/gateTown';
  import { formatDate } from '@/utils/format';
  import { Token, MsgType, PetText, PetNum, PetFormat, webSocketLink } from '@/config/tokenTest';
  import { v4 as uuidv4 } from 'uuid'

  export default {
    name: 'multi-group-chat-page',
    components: { 
      ChatView,
      SendBox,
    },
    props: {
      socket: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        chatList: [],
        chatMsgs: [],
        chatInfo: {},
        isChat: true,
        chatIndex: 0,
        version: 'gpt-4o',
        imgList: [],
        clientId: ''
      }
    },
    mounted() {
      //this.sendParam();
    },
    methods: {
      async sendMessage(msg, type, num) {
        let param = {};
        let messages = [];
        //宠物生成文案
        msg = this.petTextAdd(msg, num)
        //发送消息
        if (num !== PetNum.PETIMAGE) {
          this.chatMsgs.push({
            type:'User',
            behavior: 'chat',
            msg,
            msgType: type,
            timer: formatDate(new Date()),
            roleInfo: {
              nick: 'User',
              state: true,
            }
          })
        }    
        
        this.chatMsgs.push({
          type: 'AI',
          behavior: 'chat',
          msg: '',
          image: '',
          msgType: type,
          files: {},
          messageType: MessageTypes.Wait,
          version: this.version,
          timer: formatDate(new Date()),
          roleInfo: {
            nick: 'AI',
            state: true,
          }
        })
        

        //宠物生成文案
        //msg = this.petTextAdd(msg, num)

        //区分AI类型
        if (this.version.indexOf('gpt') >= 0) {
          messages = this.formatGPTMsg(msg, type);
        } else if (this.version.indexOf('claude') >= 0) {
          messages = this.formatClaudeMsg(msg, type);
        }

        let data = {
          model: this.version,
          messages,
        };
        if (this.version.indexOf('claude') >= 0) {
          data.anthropic_version = 'bedrock-2023-05-31';
          data.max_tokens = 4096;
        }
        param.token = Token;
        param.data = data;

        const result = await tokenChatGPT(param);
        let resultMsg = result.choices[0].message.content;

        if (num !== PetNum.PETIMAGE) {
          if (num === PetNum.PETDATA) resultMsg = `${resultMsg} ${PetText.TEXT5}`
          let len = this.chatMsgs.length;
          this.chatMsgs[len - 1].messageType = 0;
          this.chatMsgs[len - 1].msg = resultMsg;
        }

        //第一轮数据完成生成第二轮数据第三轮生成图片
        if (num === PetNum.PETINFI) {
          this.createPatData(resultMsg, type);
          this.connectSocket();
        } else if (num === PetNum.PETDATA) {
          this.sendParam(resultMsg)
        }

        this.scrollToView();
      },
      //序列号GPT消息结构
      formatGPTMsg(msg, type, list=[]) {
        let messages = [];
        let msgItem = { role: 'user' };
        let content = [];
        if (type === MsgType.Image) {
          list.forEach(item => {
            let obj = {type: 'image_url', 
              image_url:{url: item}
            };
            content.push(obj)
          })
          if (msg) {
            let msgInfo = {
              type: 'text',
              text: msg
            }
            content.push(msgInfo)
          }
          msgItem.content = content;
          messages.push(msgItem);
        } else if (type === MsgType.Text) {
          msgItem.content = msg;
          messages.push(msgItem);
        }
        return messages;
      },
      connectSocket() {
        const clientId = uuidv4();
        console.log('clientId', clientId, 'type', typeof clientId)
        this.clientId = clientId
        this.createImage(clientId);
      },
      //序列化Claude消息结构
      formatClaudeMsg(msg, type) {
        let message = [];
        if (type === MsgType.Text) {
          let content = {
            role: 'user',
            content:[]
          }
          content.content.push({type: 'text', text: msg});
          message.push(content);
        }

        return message;
      },
      petTextAdd(msg, num) {
        if (num === PetNum.PETINFI) {
          msg = `${PetText.TEXT1}  ${msg} ${PetText.TEXT2}`
        } else if (num === PetNum.PETDATA) {
          msg = `${PetText.TEXT3}  ${msg} ${PetText.TEXT4}`
        }
        return msg
      },
      createPatData(msg, type) {
        this.sendMessage(msg, MsgType.Image, PetNum.PETDATA)
      },
      async sendParam(data=null) {
        this.chatMsgs.push({
          type: 'AI',
          behavior: 'chat',
          msg: '',
          image: '',
          msgType: MsgType.Image,
          files: {},
          messageType: MessageTypes.Wait,
          version: this.version,
          timer: formatDate(new Date()),
          roleInfo: {
            nick: 'AI',
            state: true,
          }
        })

        //data = JSON.parse(data);
        //data = JSON.stringify(data);
        let param = JSON.stringify(PetFormat);
        param = JSON.parse(param);
        param.prompt['17'].inputs.seed = this.createClientId();
        param.prompt['25'].inputs.text = data;
        param.client_id = this.clientId;
        const result = await promptData(param)
        console.log('result', result)
      },
      createImage(clientId=null) {
        const socket = new WebSocket(`${webSocketLink}?clientId=${clientId}`);
        //关闭连接
        setTimeout(() => {
          socket.close();
        }, 600000) //十分钟关闭连接
        socket.addEventListener('open', (event) => {
           console.log('WebSocket 连接已打开');
           //socket.send(JSON.stringify(data))
        })
        //接受消息
        socket.addEventListener('message', (event) => {
          console.log(`接收到消息：${event.data}`);
          if (typeof event.data === 'string') {
            let data = event.data;
            data = JSON.parse(data);
            console.log('data', data)
            if (data.data.node === '28' && data.data.output) {
              this.formatBlobImg(data.data.output.string)
              socket.close();
            }
          }
        });
        // 监听连接关闭事件
        socket.addEventListener('close', (event) => {
          console.log('WebSocket 连接已关闭');
        });
        // 监听连接错误事件
        socket.addEventListener('error', (event) => {
          console.error('WebSocket 连接发生错误', event);
        });
        
      },
      formatBlobImg(images) {
        console.log('formatBlobImg', images)
        let base64Data = images[0];
        // 解码Base64字符串为Uint8Array
        let binaryData = atob(base64Data);
        let arrayBuffer = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            arrayBuffer[i] = binaryData.charCodeAt(i);
        }
        let blob = new Blob([arrayBuffer], { type: 'image/png' });
        let imageUrl = URL.createObjectURL(blob);

        //展示生成的宠物图片
        let len = this.chatMsgs.length;
        this.chatMsgs[len - 1].messageType = 0;
        this.chatMsgs[len - 1].image = imageUrl;
        this.scrollToView();
      },
      mergeBlobs(blobs) {
        const mergedBlob = new Blob(blobs);
        return mergedBlob;
      },
      isBinaryData(data) {
        if (data instanceof ArrayBuffer) {
          return true;
        }

        if (Array.isArray(data)) {
          return data.every(item => item instanceof Uint8Array);
        }

        return false;
      },
      createClientId() {
        const min = 1000000;
        const max = 10000000;
        return Math.floor(Math.random() * (max - min + 1) + min);
      },
      scrollToView() {
        setTimeout(() => {
          this.$refs.chatViewRef.scrollToBottom();
        }, 300)
      },
    }
  }
  </script>
  
<style lang="scss" scoped>
  .ai-single-chat-page{
    width: 100%;
    height: 100%;
    background: #f8f9fa;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;    
    :deep(.el-button){
      border: 1px solid #dcdfe6;
    }
    .chat-wrap{
      width: 100%;
      .tool-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px;
        gap: 12px;
      }
    }
  }
</style>
  