<template>
  <div class="ai-single-chat-page">
    <div class="aside-wrap">
      <GroupSetting ref="groupSettingView" @addChat="addChatList" />
      <ChatList :chatList="chatList" :chatInfo="chatInfo" @clickChat="clickChat" @delChat="delChat" />
    </div>
    <div class="chat-wrap">
      <ChatView :chatMsgs="chatMsgs" ref="chatViewRef" />
      <SendBox v-if="isChat" @sendMsg="sendMessage" :chatMsgs="chatMsgs" :version="version" ref="sendBoxRef" />
    </div>
  </div>
  </template>
  <script>
  import ChatView from './components/chatView.vue';
  import SendBox from './components/sendBox.vue';
  import ChatList from './components/chatList.vue';
  import GroupSetting from './components/groupSetting.vue';
  import { MessageTypes } from '@/config/aiInspect'
  import { tokenChatGPT, tokenChatClaude, TokenSendAudio, TokenSendText } from '@/api/gateTown';
  import { formatDate } from '@/utils/format';
  import { Token, MsgType } from '@/config/tokenTest';

  export default {
    name: 'multi-group-chat-page',
    components: { 
      GroupSetting,
      ChatList,
      ChatView,
      SendBox,
    },
    props: {
      socket: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        chatList: [],
        chatMsgs: [],
        chatInfo: {},
        isChat: false,
        chatIndex: 0,
        version: '',
        chatId: '',
      }
    },
    mounted() {

    },
    methods: {
      async sendMessage(msg, type, list=[]) {
        let param = {};
        let messages = [];
        let result = {};
        let resultMsg = '';
        if (type === MsgType.TextConvert) msg = list.input;
        //发送消息
        this.chatMsgs.push({
          type:'User',
          behavior: 'chat',
          msg,
          msgType: type,
          files: list,
          chatId: this.chatId,
          timer: formatDate(new Date()),
          roleInfo: {
            nick: 'User',
            state: true,
          }
        })
        
        let len = this.chatMsgs.push({
          type: 'AI',
          behavior: 'chat',
          msg: '',
          msgType: type,
          files: {},
          messageType: MessageTypes.Wait,
          version: this.version,
          chatId: this.chatId,
          timer: formatDate(new Date()),
          roleInfo: {
            nick: 'AI',
            state: true,
          }
        })
        //保存发送时的id
        let id = this.chatId;
        this.scrollToView();

        //区分AI类型
        if (this.version.indexOf('gpt') >= 0) {
          messages = this.formatGPTMsg(msg, type, list);
        } else if (this.version.indexOf('claude') >= 0) {
          messages = this.formatClaudeMsg(msg, type, list);
        }

        let data = {
          model: this.version,
          messages,
          
          //stream: true,//测试参数
        };
        if (this.version.indexOf('claude') >= 0) {
          data.anthropic_version = 'bedrock-2023-05-31';
          data.max_tokens = 4096;
        }
        param.token = Token;
        param.data = data;

        //对应的AI请求返回
        if (type === MsgType.Audio) {//音频类型分开请求
          //返回的文本类型
          this.chatMsgs[len-1].msgType = MsgType.Text;
          resultMsg = await this.sendAudio(list);
        } else if (type === MsgType.TextConvert) {//文字转语音
          resultMsg = await this.sendTextAudio(list);
        } else if (this.version.indexOf('gpt') >= 0) {
          result = await tokenChatGPT(param);
          resultMsg = result.choices[0].message.content;
        } else if (this.version.indexOf('claude') >= 0) {
          result = await tokenChatClaude(param);
          resultMsg = result.content[0].text;
        }
        
        //发送对话时对应的聊天窗口
        let index = this.chatList.findIndex(item => item.id === id)

        //返回数据到对应对话列表
        let lens = this.chatList[index].chatMsgs.length - 1;
        this.chatList[index].chatMsgs[lens].messageType = 0;
        this.chatList[index].chatMsgs[lens].msg = resultMsg;
  
        //存入列表
        this.chatList[index].chatInfo = {};
        this.chatList[index].chatInfo.msg = resultMsg;
        this.chatList[index].chatInfo.timer = formatDate(new Date());
        this.chatList[index].chatInfo.msgType = this.chatList[index].chatMsgs[lens].msgType;
        this.scrollToView();
      },
      addChatList(id, version) {
        let newChat = {id, version, activity: false, chatMsgs: [], chatInfo: {}, isDelPop:false, };
        this.chatList.push(newChat);
      },
      clickChat(id) {
        this.chatList.forEach((item, index) => {
        item.activity = false;
          if (item.id === id) {
            item.activity = true;
            this.chatIndex = index;
            this.version = item.version;
            this.chatMsgs = item.chatMsgs;
            this.chatId = item.id
            this.scrollToView();
            if (this.isChat) this.$refs.sendBoxRef.clearSendBox();
          }
        })
        this.isChat = true;
      },
      delChat(item) {
        this.chatList.forEach((chat, index) => {
          if (chat.id === item.id) {
            this.chatList.splice(index, 1)
          }
        })
      },
      //序列号GPT消息结构
      formatGPTMsg(msg, type, list) {
        let messages = [];
        let msgItem = { role: 'user' };
        let content = [];
        if (type === MsgType.Image) {
          list.forEach(item => {
            let obj = {type: 'image_url', 
              image_url:{url: item}
            };
            content.push(obj)
          })
          if (msg) {
            let msgInfo = {
              type: 'text',
              text: msg
            }
            content.push(msgInfo)
          }
          msgItem.content = content;
          messages.push(msgItem);
        } else if (type === MsgType.Text) {
          msgItem.content = msg;
          messages.push(msgItem);
        }
        return messages;
      },
      //序列化Claude消息结构
      formatClaudeMsg(msg, type, list) {
        let message = [];
        if (type === MsgType.Text) {
          let content = {
            role: 'user',
            content:[]
          }
          content.content.push({type: 'text', text: msg});
          message.push(content);
        }

        return message;
      },
      async sendAudio(file) {
        const param = {};
        const data = new FormData();
        data.append('file', file.raw);
        data.append('language', 'zh');
        data.append('model', 'whisper');
        data.append('response_format', 'json');
        data.append('temperature', 1);

        param.data = data;
        param.token = Token;
        const result = await TokenSendAudio(param);
        return result.text;
      },
      async sendTextAudio(file) {
        const param = {};
        delete file.type
        param.data = file;
        param.token = Token;
        const result = await TokenSendText(param);

        const url = this.binaryAudio(result);
        return url
      },
      binaryAudio(arrayBuffer) {
        const blob = new Blob([arrayBuffer], { type: 'audio/wav' });
        const url = URL.createObjectURL(blob);
        return url;
      },
      scrollToView() {
        this.$refs.chatViewRef.scrollToBottom();
      },
    }
  }
  </script>
  
<style lang="scss" scoped>
  .ai-single-chat-page{
    width: 100%;
    height: 100%;
    background: #f8f9fa;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;    
    :deep(.el-button){
      border: 1px solid #dcdfe6;
    }
    .aside-wrap{
      width: 460px;
      min-width: 460px;
      padding: 10px 5px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-self: flex-start;
      align-items: center;
      gap: 10px;
      border-right: 1px solid #cdcdcd;
    }
    .chat-wrap{
      width: calc(100% - 460px);
      .tool-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px;
        gap: 12px;
      }
    }
  }
</style>
  