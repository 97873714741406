<template>
  <div class="gateTown-ai-inspect">
    <el-tabs v-model="activeTab" @tab-click="handleClick" >
      <el-tab-pane label="AI聊天" name="singleChat" class="inspect-content">
        <SingleChat />
      </el-tab-pane>
      <el-tab-pane label="多人群聊" name="groupChat" class="inspect-content">
        <GroupChat :socket="socket" @onInitSocket="initSocket" ref="groupChatView"/>
      </el-tab-pane>
      <el-tab-pane label="聊天" name="tokenInspect" class="inspect-content">
        <TokenTest :socket="socket" ref="groupChatView"/>
      </el-tab-pane>
      <el-tab-pane label="宠物生成" name="petGeneration" class="inspect-content">
        <PetGeneration :socket="socket" ref="groupChatView"/>
      </el-tab-pane>
    </el-tabs>
  </div>
  </template>
  
  <script>
  import SingleChat from './singleChat/singleChat.vue'
  import GroupChat from './groupChat/groupChat.vue'
  import TokenTest from './tokenTest/index.vue'
  import PetGeneration from './petGeneration/index.vue'
  import { WebSocketClient } from '@/utils/websocket'
  import { ConnectClientTypes } from '@/config/aiInspect'
  export default {
    name: 'gateTown-ai-inspect',
    components: { 
      SingleChat,
      GroupChat,
      TokenTest,
      PetGeneration,
    },
    data() {
      return {
        activeTab: 'singleChat',
        socket: null,
      }
    },
    mounted() {},
    methods: {
      handleClick(index) {
        console.log('index:', index)
      },
      initSocket(uid, callback) {
        this.uid = uid;
        this.socket = new WebSocketClient('wss://dev-gate.woa.com/ws', uid, {
          onOpen: () => {
            callback && callback();
          },
          onNotice: (message) => {
            console.log('on Notice:', message)
          },
          onMessage: (message) => {
            console.log('on message:', message)
            this.$refs.groupChatView.onServerMessage(message);
          },
          onClose: (event) => {
            this.$refs.groupChatView?.socketClose(event);
          },
          onReConnect: () => {
            const groupInfo = this.$refs.groupChatView.getCurrentGroupInfo();
            this.socket.send({
              reconnection_request: {
                group_id: groupInfo.group_id,
                uid: this.uid,
                client_type: ConnectClientTypes.USERPLAYER
              }
            })
          }
        });
          // this.socket.send('Hello, WebSocket!');
          // this.socket.close();
      }
    },
    beforeDestroy() {
      if (this.socket) {
        this.socket.close();
      }
      this.socket = null;
    }
  }
  </script>
  
<style lang="scss" scoped>
  .gateTown-ai-inspect{
    width: 100%;
    height: 100vh;
    padding: 8px;
    :deep(.el-tabs__content){
      height: calc(100vh - 90px)
    }
    .inspect-content{
      height: 100%;
    }
  }
</style>
  