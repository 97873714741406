  <template>
    <div>
      <el-dialog
        :title="$t('views.incentiveAccount.dialog.usageLimitTitle')"
        :visible.sync="visible"
        width="424px"
        :show-close="false"
        custom-class="usage-limit-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        >
        <div class="title-line" slot="title">
          <div class="title">{{ $t('views.incentiveAccount.dialog.usageLimitTitle') }}</div>
          <div class="close-btn" @click="visible = false"></div>
        </div>
        <div class="dialog-content-wrap">
          <div class="input-wrap">
            <div class="props-box">
              <div class="input-label">{{ $t('views.incentiveAccount.dialog.limitProps') }}</div>
              <el-switch
                v-model="enableDailyLimit"
                active-color="#00FFF6"
                inactive-color="#666"
              >
              </el-switch>
            </div>

            <el-input-number
              v-show="enableDailyLimit"
              :placeholder="$t('views.incentiveAccount.dialog.limitPH') + `$${sysLimit.toFixed(4)}`"
              v-model="limit"
              :controls="false"
              :style="{width: '100%'}"
              :min="0"
              class="lit-custom-dialog-input"
              clearable>
            </el-input-number>
            <div class="error-tips" v-if="showErrorTips">{{ $t('views.incentiveAccount.dialog.limitLimitError') + `$${sysLimit.toFixed(4)}` }}</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="btn-box">
            <div :class="{'btn-style': true, 'cancel-btn': true}" @click="visible = false">{{ $t('views.incentiveAccount.dialog.cancel') }}</div>
            <div :class="{'btn-style': true, 'submit-btn': true, 'disable': !canSubmit}" @click="sendDayLimit">{{ $t('views.incentiveAccount.dialog.submit') }}</div>
          </div>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { editProjectDayLimit } from '@/api/token';
import { Token_Apply_State } from '@/config/token';

  export default {
    name: 'usage-limit-dialog',
    props: {
    },
    data() {
      return {
        enableDailyLimit: false,
        projectInfo: {},
        showErrorTips: false,
        limit: 0,
        sysLimit: 0,
        visible: false
      }
    },
    mounted() {
    },
    computed: {
      canSubmit() {
        if (this.enableDailyLimit) {
          return this.limit !== ''
        }
        return true
      }
    },
    methods: {
      sendLimit() {
        this.visible = false
      },
      open(info, sysLimit) {
        this.projectInfo = info;
        this.limit = Number(info.day_limit) || ''
        this.sysLimit = sysLimit || 1000;
        this.showErrorTips = false;
        this.enableDailyLimit = info.limit_turn_on === Token_Apply_State.Gate_Yes ?  true : false;
        this.visible = true;
      },
      onCancel() {
        this.$emit('onCancel');
      },
      onConfirm() {
        this.$emit('onConfirm');
      },
      async sendDayLimit() {
        if (!this.limit  || this.sysLimit < this.limit) {
          this.showErrorTips = true;
          return;
        }
        const param = {
          project_id: this.projectInfo.project_id,
          day_limit: Number(this.limit),
          limit_turn_on: this.enableDailyLimit ? Token_Apply_State.Gate_Yes : Token_Apply_State.Gate_No
        }
        console.log(param)
        const result = await editProjectDayLimit(param);
        if (result.code == 0 && result.data) {
          this.visible = false;
          this.$emit('onConfirm', this.projectInfo, result.data);
        }
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .usage-limit-dialog{
    .title-line {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      .title{
        color: #DDD;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        // align-self: stretch;
      }
      .close-btn{
        width: 24px;
        height: 24px;
        background: url('@/assets/postApplication/apiKey/icon_dialog_close.png');
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    .dialog-content-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 24px;
      :deep(.el-input__inner){
        color: #999;
      }
      :deep(.el-input__inner::placeholder){
        color: #666;
      }
      .input-wrap{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 16px;
        .props-box{
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
          .input-label{
            color: #999;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; 
          }
        }
        .error-tips{
          color: #FF5C51;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          margin-top: -10px;
        }
      }
    }
    .btn-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      gap: 16px;
      .btn-style{
        display: flex;
        height: 32px;
        width: 80px;
        padding: 5px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        cursor: pointer;
      }
      .cancel-btn{
        border: 1px solid #666;
        color: #666;
      }
      .submit-btn{
        background: #00FFF6;
      }
      .submit-btn:hover{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #00FFF6;
      }
      .disable{
        opacity: 0.3;
        background: #00FFF6 !important;
      }
    }
  }
  
  </style>
  