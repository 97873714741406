import { render, staticRenderFns } from "./sendBox.vue?vue&type=template&id=5293603b&scoped=true"
import script from "./sendBox.vue?vue&type=script&lang=js"
export * from "./sendBox.vue?vue&type=script&lang=js"
import style0 from "./sendBox.vue?vue&type=style&index=0&id=5293603b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5293603b",
  null
  
)

export default component.exports