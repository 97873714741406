<template>
  <div class="gate-sign-in">
    <div class="sign-box">
      <div class="title">{{ $t('views.signIn.title') }}</div>
      <el-form label-width="0px" ref="form" :model="form" :rules="rules" :style="{ width: '100%'}" >
        <el-form-item label="" prop="email">
          <el-input v-model.trim="form.email" :placeholder="$t('views.signUp.emailPh')"></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input v-model.trim="form.password" :placeholder="$t('views.signUp.passwordPh')" show-password @keyup.enter.native="submitFunc"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="sign-btn" :loading="loading" @click="submitFunc">{{ $t('views.signIn.signInBtn') }}</el-button>

      <div class="tool-box">
        <div class="back-box" @click="gotoForget">{{ $t('views.signIn.forget') }}</div>
        <div class="sign-in-tips-box">
          <div class="tips">{{ $t('views.signIn.noAccount') }}</div>
          <div class="sign-up-link" @click="gotoSignUp">{{ $t('views.signIn.signUpLink') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { accountLogin } from '@/api/account';
import { cacheViewsPath, litGateSessionKey } from '@/config';
import { Local } from '@/utils/storage';
import { UserModule } from '@/store/modules/user'
import { validateSignInEmail, validateSignPassword } from '@/utils/validator';

export default {
  name: 'signIn',
  components: {  },
  data() {
    return {
      rules: {
        email: [
          { validator: validateSignInEmail, trigger: 'submit' }
        ],
        password: [
          { validator: validateSignPassword, trigger: 'submit' }
        ],

      },
      form: {
        email: '',
        password: ''
      },
      email: '',
      password: '',
      rememberState: false,
      loading: false
    }
  },
  mounted() {
   
  },
  methods: {
    submitFunc() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.signInFunc(); 
        }
      });
    },
    async signInFunc() {
      const params = { 
        email: this.form.email,
        user_password: this.form.password
      };
      this.loading = true;
      const result = await accountLogin(params);
      this.loading = false;
      if (result.code === 0) {
        this.$message.success(this.$t('views.signIn.success')); 
        const { data } = result;
        Local.set(litGateSessionKey, data);
        UserModule.updateUserInfo();
        const cachePath = Local.get(cacheViewsPath);
        console.log('cache Path:', cachePath)
        if (cachePath && cachePath.path.indexOf('/inspect') < 0){
          Local.remove(cacheViewsPath);
          this.$router.push(cachePath);
          return;
        }
        this.gotoHome();
        return;
      }
      if (result.code){
        this.$message.error(this.$t(`accountCode.code${result.code}`))
      }
    },
    gotoForget() {
      this.$router.push({ path: '/account/forget' })
    },
    gotoSignUp() {
      this.$router.push({ path: '/account/signup' })
    },
    gotoHome() {
      this.$router.push({ path: '/gate' })
    }
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>
.gate-sign-in{
  background-image: url('@/assets/account/bg.png');
  background-size: 100% 100%;
  width: 100vw;
  min-width: 1280px;
  padding-top: 80px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .sign-box{
    width: 560px;
    display: inline-flex;
    padding: 40px 64px 64px 64px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border: 2px solid;
    border-image: linear-gradient(215deg, rgba(56, 254, 238, 0.38), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)) 1;
    background: radial-gradient(115.63% 103.73% at 126.07% -32.66%, rgba(0, 69, 64, 0.99) 0%, rgba(12, 12, 12, 0.99) 100%);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(2.0190839767456055px);
    
    .title{
      color: #DDD;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 16px;
    }
    .tips-box{
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .remember{
        color: #666;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .forget{
        color: #00FFF6;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        cursor: pointer;
      }
    }
    .sign-btn{
      display: flex;
      width: 100%;
      padding: 14px 24px;
      background: #00FFF6;
      justify-content: center;
      color:  #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 16px;
    }
    .tool-box{
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .back-box{
        color: #00FFF6;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        cursor: pointer;
      }
      .sign-in-tips-box{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .tips{
          color: #666;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; 
        }/* 150% */
        .sign-up-link{
          color: #00FFF6;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          margin-left: 5px;
          line-height: 18px; /* 150% */
          cursor: pointer;
        }
      }
    }
  }
}
</style>
