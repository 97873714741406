<template>
  <div class="gate-pet-page">
    <iframe src="/gatePet/index.html" width="100%" height="500px"></iframe>
  </div>
</template>

<script lang="ts">

export default {
  name: 'gateHome',
  components: { },
  data() {
    return {
      cacheBgColor: '',
    }
  },
  mounted() {
    this.cacheBgColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#000';
    window.scrollTo({
      top: 0
    });
  },
  methods: {

  },
  beforeDestroy() {
    document.body.style.backgroundColor = this.cacheBgColor;
  }
}
</script>

<style lang="scss" scoped>
.gate-pet-page{

}
</style>