<template>
  <div class="inspect-send">
    <div class="send-box-warp">
      <div class="input-outer-box" @keydown.enter="onSendMsg">
        <el-input type="text" v-model="msg" :disabled="version === 'whisper' || version === 'tts-hd'" placeholder="请输入"></el-input>
      </div>
      <div class="send-option">
        <div class="send-btn" @click="onSendMsg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageTypes } from '@/config/aiInspect';
import { MsgType, PetNum } from '@/config/tokenTest';

export default {
  name: 'send-box',
  props:{
    chatMsgs: {
      type: Array,
      default: () => []
    },
    version: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      msg: '',
      isShowAudioPop: false,
    }
  },
  mounted() {
    
  },
  methods:{
    onSendMsg() {
      if (this.msg || this.imgList.length > 0 || Object.keys(this.files).length > 0) {
        let len = this.chatMsgs.length - 1;
        //ai是否在回答中
        if (this.chatMsgs.length > 0 && this.chatMsgs[len].type === 'AI' && this.chatMsgs[len].messageType === MessageTypes.Wait) {
          this.$message({message: '请等待机器人回答完毕', type: 'warning'});
          return;
        }

        this.$emit('sendMsg', this.msg, MsgType.Text, PetNum.PETINFI)
        this.clearSendBox();
      }
    },
    clearSendBox() {
      this.msg = '';
    },
  }
}
</script>

<style lang="scss" scoped>
.inspect-send{
  width: 100%;
  position: relative;
}

.send-box-warp{
  width: 100%;
  display: flex;
  position: relative;
  background: #f8f9fa;
  padding: 10px 8px 10px;
  .input-outer-box{
    width: 100%;
    min-height: 50px;
    padding: 0 16px;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    :deep .el-input__inner{
      border: 0;
      margin-top: 5px;
    }
  }
  .send-option{
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-right: 10px;
    .send-btn{
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: url('@/assets/aiInspect/tokenTest/send-icon.png') no-repeat;
      background-size: 100% 100%;
    }
    .audio-convert-btn{
      width: 35px;
      height: 35px;
      cursor: pointer;
      margin-right: 15px;
      background: url('@/assets/aiInspect/tokenTest/audio-text-icon.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  
}

.img-list-warp{
  position: absolute;
  top: -120px;
  width: 100%;
  height: 120px;
  padding: 10px 6px 6px 6px;
  background: #ffffff;
  .img-list{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    .delete-all-btn{
      position: absolute;
      top: 30px;
      right: 0;
      width: 20px;
      height: 20px;
      background: url('@/assets/aiInspect/tokenTest/delete-all-icon.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .img-item-warp{
    width: 100px;
    height: 100px;
    margin-right: 10px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .delete-btn{
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      background: url('@/assets/aiInspect/tokenTest/delete-icon.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}
</style>