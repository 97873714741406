import { RouteConfig } from 'vue-router';

// gateTown项目web端路由

/**
 * 定义默认路由
 * @description 当单独打包时，会设置默认路由
 */
export const defaultRoutes: Array<RouteConfig> = [];

/**
 * 定义静态路由
 * @description 前端控制直接改 dynamicRoutes 中的路由，后端控制不需要修改，请求接口路由数据时，
 * 会覆盖 dynamicRoutes 第一个顶级 children 的内容（全屏，不包含 layout 中的路由出口）
 * @returns 返回路由菜单数据
 */
export const staticRoutes: Array<RouteConfig> = [
  {
    path: '/gatepet',
    name: 'guide',
    component: () => import('@/views/gatePet/index.vue'),
    meta: {
      noSession: true
    },
  },
];
